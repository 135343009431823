import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar, useUserProfile } from '@didomi/utility-react';
import { DidomiTextInputField, DidomiButton } from '@didomi/ui-atoms-react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { usePatchUser } from '@hooks';
import { UserInput } from '@types';
import { UserDeleteModal } from '@modals';
import { errorSnackbarOptions, snackbarOptions } from '@utils';

const PatchUserSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

const UserSettingsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userSettingsAssets = useSPAAssetsUrl('@didomi-spa/user-settings');
  const { userProfile, updateUserProfile } = useUserProfile({ refetchOnFocus: false }, true);
  const { displaySnackbar } = useSnackbar();

  const { mutate: mutateUser, isLoading: isLoadingPatch } = usePatchUser({
    onSuccess({ data: updatedUser }) {
      updateUserProfile(updatedUser);
      displaySnackbar('The user has been updated', snackbarOptions);
    },
    onError() {
      displaySnackbar('The user could not be updated. Please try again or contact support@didomi.io', errorSnackbarOptions);
    },
  });

  const handleFormSubmit = (values: Omit<UserInput, 'id'>) => {
    mutateUser({
      ...values,
      id: userProfile.id,
    });
  };

  return (
    <div id="user-settings-page" className="flex flex-col justify-between h-full ml-l pt-l">
      <div>
        <div id="user-settings-header" className="mb-xxl">
          <h1 className="h1-catchline text-primary-blue-6">Account settings</h1>
          <p className="body-big-light text-primary-blue-6">Update your username and manage all your account settings.</p>
        </div>
        <div id="user-settings-main" className="flex justify-between	">
          <div id="user-settings-form" className="w-[400px] xl:w-[600px]">
            <Formik
              initialValues={{
                name: userProfile?.name,
                email: userProfile?.email,
              }}
              enableReinitialize={true}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={PatchUserSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, values, dirty }) => (
                <form onSubmit={handleSubmit}>
                  <DidomiTextInputField disabled name="email" label="Email address" value={values.email}></DidomiTextInputField>
                  <DidomiTextInputField name="name" label="Name" value={values.name} className="mb-xs"></DidomiTextInputField>
                  <DidomiButton type="submit" className="mb-xxl mt-xs" disabled={!dirty || isLoadingPatch}>
                    Save changes
                  </DidomiButton>
                </form>
              )}
            </Formik>
          </div>
          <div id="user-settings-illustration" className="hidden lg:block mx-auto xxl:mr-[128px]">
            <img src={`${userSettingsAssets}/assets/illustrations/settings-illustration.svg`} className="w-[400px]" alt="" data-cy="user-settings-illustration" />
          </div>
        </div>
      </div>
      <div className="flex flex-col pr-l mb-l">
        <hr className="w-full border-neutral-gray-4 mb-s" />
        <DidomiButton type="button" variant="destructive" size="small" onClick={() => setIsModalOpen(true)} data-cy="delete-my-account-btn">
          Delete my account
        </DidomiButton>
      </div>
      <UserDeleteModal isModalOpen={isModalOpen} setModalState={setIsModalOpen}></UserDeleteModal>
    </div>
  );
};

export { UserSettingsPage };
