import React, { useState } from 'react';
import { tx } from '@twind/core';
import { DidomiButton, DidomiIcon, DidomiModal, DidomiModalHeader, DidomiModalActions } from '@didomi/ui-atoms-react';
import { useAuthMethods, useSnackbar, useUserProfile } from '@didomi/utility-react';
import { useDeleteUser } from '@hooks';
import { errorSnackbarOptions } from '@utils';

export const UserDeleteModal = ({ isModalOpen, setModalState }) => {
  const [isUserDeleted, setIsUserDeleted] = useState(false);

  const { logout } = useAuthMethods();
  const { userProfile } = useUserProfile({ refetchOnFocus: false }, true);
  const { displaySnackbar } = useSnackbar();
  const { mutate: deleteUser, isLoading: isLoadingDelete } = useDeleteUser({
    onSuccess() {
      setIsUserDeleted(true);
    },
    onError() {
      displaySnackbar('We could not delete your account. Please try again or contact support@didomi.io', errorSnackbarOptions);
    },
  });

  const handleDialogChange = (e: CustomEvent) => {
    /* istanbul ignore next */
    if (isModalOpen && e.detail === false) {
      setModalState(e.detail);
    }
  };

  const handleLogoutClick = () => {
    setModalState(false);
    logout({});
  };

  const handleModalConfirm = () => {
    deleteUser({
      id: userProfile.id,
    });
  };

  return (
    <DidomiModal
      isOpen={isModalOpen}
      onOpenChange={handleDialogChange}
      permanent={JSON.stringify(isUserDeleted)}
      initialFocus="user-settings-didomi-icon"
      data-testid="user-settings-delete-account-modal"
    >
      <div
        className={tx({
          hidden: !isUserDeleted,
        })}
      >
        <DidomiIcon name="pmp-success" className="user-settings-mb-xs user-settings-text-primary-blue-5"></DidomiIcon>
        <DidomiModalHeader modalTitle="You have succesfully deleted your account" modalSubTitle="Done!"></DidomiModalHeader>
        <DidomiModalActions>
          <DidomiButton variant="secondary" onClick={handleLogoutClick}>
            Close
          </DidomiButton>
        </DidomiModalActions>
      </div>

      <div
        className={tx({
          'user-settings-hidden': isUserDeleted,
        })}
        style={{ maxWidth: '440px' }}
      >
        <DidomiIcon name="warning" className="user-settings-mb-xs"></DidomiIcon>
        <DidomiModalHeader
          modalTitle="You're about to delete your account"
          modalSubTitle="Please pay attention"
          modalDescription="This action is irreversible are you sure you want to continue?"
        ></DidomiModalHeader>
        <DidomiModalActions>
          <DidomiButton
            data-tracking={/* istanbul ignore next */ userProfile?.self_register ? 'cancel-confirm-delete-account-self-service' : 'cancel-confirm-delete-account'}
            style={{ maxWidth: '200px' }}
            variant="secondary"
            onClick={() => setModalState(false)}
          >
            Cancel
          </DidomiButton>
          <DidomiButton
            data-tracking={/* istanbul ignore next */ userProfile?.self_register ? 'confirm-delete-account-self-service' : 'confirm-delete-account'}
            style={{ maxWidth: '200px' }}
            variant="destructive-filled"
            onClick={() => handleModalConfirm()}
            disabled={isLoadingDelete}
          >
            Yes, delete account
          </DidomiButton>
        </DidomiModalActions>
      </div>
    </DidomiModal>
  );
};
