import { SnackbarOptions } from '@didomi/utility/dist/typings/src/snackbar/models/snackbar.model';

export const snackbarOptions: SnackbarOptions = {
  variant: 'message',
  duration: 5000,
  action: {
    name: 'Ok',
    closeInAction: true,
  },
};

export const errorSnackbarOptions: SnackbarOptions = {
  variant: 'error',
  permanent: true,
  action: {
    name: 'Close',
    closeInAction: true,
  },
};
