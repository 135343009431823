import { useAuthMethods, useAuthToken } from '@didomi/utility-react';
import { addAxiosResponseInterceptors } from '@didomi/helpers/dist/response-interceptors/axios/axios-response-interceptors';
import { UserProfile } from '@didomi/utility/dist/typings/src/user-profile/models/user-profile.model';
import { useMutation, UseMutationOptions } from 'react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { UserInput } from '@types';

/**
 * Update the user
 * */
export const usePatchUser = (options: UseMutationOptions<AxiosResponse<UserProfile>, AxiosError, UserInput>) => {
  const token = useAuthToken();
  const { logout } = useAuthMethods();
  return useMutation(async (userInput: UserInput) => {
    const axiosWithInterceptors = addAxiosResponseInterceptors(axios, { onExpiredToken: logout, onForbidden: logout });
    return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}/users/${userInput.id}`, userInput, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, options);
};
